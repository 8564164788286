import { useQuery } from '@tanstack/react-query'
import { useRevalidator } from '@remix-run/react'

import useSupabase from '~/core/hooks/use-supabase'

/**
 * @name useUser
 */
function useUser(
  params: {
    enabled?: boolean
    refetchOnMount?: boolean
    refetchOnWindowFocus?: boolean
  } = {
    enabled: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  },
) {
  const { revalidate } = useRevalidator()
  const client = useSupabase()
  const queryKey = ['user']

  return useQuery({
    ...params,
    queryKey,
    queryFn: async () => {
      return client.auth
        .getUser()
        .then((result) => {
          if (result.error) {
            return Promise.reject(result.error)
          }

          return result.data.user
        })
        .catch(() => {
          revalidate()
        })
    },
  })
}

export default useUser
